@import "_variables.scss";
@import "~bootstrap/scss/bootstrap";

h1 {
  font-size: 2rem;
  font-weight: 800;
  color: $deep-sea-blue;
}

h2 {
  font-size: 1.75rem;
  font-weight: bold;
  font-stretch: condensed;
  color: $purple;
}

label {
  font-weight: $font-weight-bold;
}

b,
strong {
  font-weight: $font-weight-bold;
}

.alert {
  padding: ($spacer * 0.25) $spacer;
  margin: 0;
}

.alert-dismissible {
  .close {
    padding: ($spacer * 0.25);
    line-height: 0.75;
  }
}

.btn {
  padding: ($spacer * 0.25) ($spacer * 2.5);
}

.btn-link {
  padding: 0;
}

.list-group-item {
  border: none;
  outline: 1px solid $border-color;
}

.dropdown-header {
  color: $purple;
  font-stretch: condensed;
}

.form-check-label {
  font-weight: $font-weight-normal;
}

.modal-header,
.modal-content,
.modal-header,
.modal-footer {
  border-radius: 0;
}

.modal-content {
  background-color: theme-color("light");
}
.modal-header {
  color: color("white");
  background-color: $deep-sea-blue;

  .close {
    color: color("white");
  }
}
.modal-footer {
  background-color: #fff;
  border-top: 1px solid $gray-300;
}
