@font-face {
  font-family: HelveticaNeueLTStd;
  src: url(/fonts/helveticaneueltstd-roman-webfont.woff) format("woff");
  font-weight: 400;
}
@font-face {
  font-family: HelveticaNeueLTStd;
  src: url(/fonts/helveticaneueltstd-md-webfont.woff) format("woff");
  font-weight: 600;
}
@font-face {
  font-family: HelveticaNeueLTStd;
  src: url(/fonts/helveticaneueltstd-hv-webfont.woff) format("woff");
  font-weight: 800;
}
@font-face {
  font-family: HelveticaNeueLTStd;
  src: url(/fonts/helveticaneueltstd-condensed-webfont.woff) format("woff");
  font-weight: 400;
  font-stretch: condensed;
}
