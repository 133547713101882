$azure-10: rgba(0, 153, 255, 0.1);
$azure: #03aeef;
$black: #000000;
$brownish-grey: #666666;
$dark-grey-blue: #2a2968;
$deep-sea-blue-10: rgba(6, 85, 144, 0.1);
$deep-sea-blue: #065590;
$dusk-blue: #25408f;
$medium-green: #41ad49;
$orangey-yellow: #fcaf17;
$reddish-orange: #f05123;

$blue: $azure;
$indigo: #6610f2;
$purple: $dark-grey-blue;
$pink: #e83e8c;
$red: $reddish-orange;
$orange: $orangey-yellow;
$yellow: #ffc107;
$green: $medium-green;
$teal: #20c997;
$cyan: #17a2b8;

$theme-colors: (
  "light": #f6f6f6,
);

$font-family-sans-serif: HelveticaNeueLTStd, Arial, sans-serif;
$font-weight-bold: 600;
$font-weight-bolder: 800;

$modal-content-bg: theme-colors("light");
$modal-content-border-width: 0;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
